<template>
  <v-container>
    <div>
      <template>
        <v-card>
          <v-row>
            <v-col v-if="searching" cols="12">
              <p class="text-center mt-5">
                <v-icon>fas fa-atom fa-spin</v-icon> Carregando ...
              </p>
            </v-col>
          </v-row>
          <v-tabs v-model="tab" centered icons-and-text fixed-tabs>
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1" :disabled="searching">
              Assinaturas
              <v-icon>fas fa-key</v-icon>
            </v-tab>

            <v-tab href="#tab-2" :disabled="searching">
              Usuário
              <v-icon>fas fa-user</v-icon>
            </v-tab>

            <v-tab href="#tab-3" :disabled="searching">
              Log
              <v-icon>fas fa-file-invoice-dollar</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text v-if="me && me.id">
                  <h3>Assinaturas:</h3>
                  <v-data-table
                    v-if="me && me.licenses"
                    :headers="[
                      { text: 'Nome', value: 'name' },
                      { text: 'Código do assinate', value: 'subscriber_code' },
                      { text: 'Ativo', value: 'active' },
                      { text: 'Acessos', value: 'access' },
                      { text: 'Criação', value: 'created' },
                      { text: 'Última atualização', value: 'updated' }
                    ]"
                    :items="me.licenses"
                    hide-actions
                    class="elevation-1"
                    loading="true"
                  >
                    <template v-slot:item.access="{ item }">
                      <span
                        >{{ item.access }} site<span
                          v-if="item.access && item.access > 1"
                          >s</span
                        ></span
                      >
                    </template>
                    <template v-slot:item.active="{ item }">
                      <span
                        ><span v-if="item.active"
                          ><v-icon color="success">fas fa-check</v-icon></span
                        ><span v-else
                          ><v-icon color="error">fas fa-times</v-icon></span
                        ></span
                      >
                    </template>
                    <template v-slot:item.updated="{ item }">
                      <span>{{
                        item.updated | moment('D/MM/YYYY HH:mm')
                      }}</span>
                    </template>
                    <template v-slot:item.created="{ item }">
                      <span>{{
                        item.created | moment('D/MM/YYYY HH:mm')
                      }}</span>
                    </template>
                  </v-data-table>
                  <v-divider></v-divider>
                  <hr class="my-4" />
                  <h3>Minhas lojas:</h3>
                  <div class="text-right">
                    <v-btn
                      color="success"
                      :disabled="searching"
                      @click="openAddStore()"
                      >Adicionar loja
                      <v-icon class="ml-2">fas fa-plus</v-icon></v-btn
                    >
                  </div>
                  <v-data-table
                    v-if="me && me.stores"
                    :headers="[
                      { text: 'Nome', value: 'name' },
                      { text: 'Ações', value: 'actions', align: 'end' }
                    ]"
                    :items="me.stores"
                    hide-actions
                    class="elevation-1"
                    loading="true"
                  >
                    <template v-slot:item.actions="{ item }">
                      <div class="text-right">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :disabled="searching"
                              color="red"
                              v-bind="attrs"
                              v-on="on"
                              icon
                              @click="removeStore(item)"
                              ><v-icon>fas fa-times</v-icon></v-btn
                            >
                          </template>
                          <span>Remover loja</span>
                        </v-tooltip>
                      </div>
                    </template>
                    <template v-slot:item.active="{ item }">
                      <span
                        ><span v-if="item.active"
                          ><v-icon color="success">fas fa-check</v-icon></span
                        ><span v-else
                          ><v-icon color="error">fas fa-times</v-icon></span
                        ></span
                      >
                    </template>
                    <template v-slot:item.updated="{ item }">
                      <span>{{
                        item.updated | moment('D/MM/YYYY HH:mm')
                      }}</span>
                    </template>
                    <template v-slot:item.created="{ item }">
                      <span>{{
                        item.created | moment('D/MM/YYYY HH:mm')
                      }}</span>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-2">
              <v-card flat>
                <v-card-text v-if="me && me.id">
                  <h3>Usuário</h3>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="me.name"
                        label="Nome (usuário)"
                        hide-details="auto"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="me.email"
                        label="Email"
                        hide-details="auto"
                        disabled
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="me.price_history_limit"
                        label="Histórico de preços"
                        hide-details="auto"
                        hint="Limite de dias para mostrar histórico de preços"
                        required
                        type="number"
                        max="999"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="me.keepa_key"
                        label="Chave Keepa"
                        hide-details="auto"
                        hint="Chave para integrações com keepa"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="me.password"
                        :append-icon="
                          passShow ? 'fas fa-eye' : 'fas fa-eye-slash'
                        "
                        :type="passShow ? 'text' : 'password'"
                        name="password"
                        label="Nova senha"
                        hint="Indique a nova senha"
                        @click:append="passShow = !passShow"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-right">
                      <v-btn
                        :disabled="searching"
                        color="success"
                        @click="updateMe()"
                        >Salvar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-3">
              <v-card flat>
                <v-card-text>
                  <h3>Log de integração:</h3>
                  <p>Últimos 30 registros</p>
                  <div class="text-center">
                    <v-btn
                      :disabled="searching"
                      color="warning"
                      @click="fetchLog()"
                      >Carregar log
                      <v-icon class="ml-2">fas fa-sync</v-icon></v-btn
                    >
                  </div>
                  <v-data-table
                    v-if="logs && logs.length > 0"
                    :headers="[
                      { text: 'Ação', value: 'action' },
                      { text: 'Mensagem', value: 'message' },
                      { text: 'Data', value: 'created_at' }
                    ]"
                    :items="logs"
                    hide-actions
                    class="elevation-1"
                    loading="true"
                  >
                    <template v-slot:item.created_at="{ item }">
                      <span>{{
                        item.created_at | moment('D/MM/YYYY HH:mm')
                      }}</span>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </template>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="searching"
            color="warning"
            v-bind="attrs"
            v-on="on"
            class="mr-3"
            style="margin-bottom: 50px"
            absolute
            fab
            bottom
            fixed
            right
            @click="getMe()"
            ><v-icon>fas fa-sync</v-icon></v-btn
          >
        </template>
        <span>Recarregar informações de usuário</span>
      </v-tooltip>
    </div>

    <v-dialog v-model="dialogs.addStore" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline">Adicionar Loja</v-card-title>
        <v-card-text>
          <v-row>
            <v-col sm="12">
              <v-select
                :disabled="searching"
                v-model="addStore"
                :items="stores"
                item-value="id"
                item-text="name"
                label="Loja"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="searching"
            color="red darken-1"
            text
            @click="dialogs.addStore = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            :disabled="searching"
            color="green darken-1"
            text
            @click="addNewStore()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'home',
  components: {},
  data() {
    return {
      me: {},
      dialogs: {
        addStore: false
      },
      addStore: null,
      tab: 'tab-2',
      searching: true,
      logs: [],
      stores: [],
      passShow: false
    }
  },
  methods: {
    getMe() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('get', `me`)
        .then(result => {
          console.debug(result)
          vm.searching = false
          vm.me = result
        })
        .catch(error => {
          console.debug(error)
          vm.searching = false
        })
    },
    fetchLog() {
      var vm = this
      vm.logs = []
      vm.searching = true
      vm.$API
        .Request('get', `me/log`)
        .then(result => {
          vm.searching = false
          vm.logs = result
        })
        .catch(error => {
          console.debug(error)
          vm.searching = false
        })
    },
    updateMe() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `me/update`, vm.me)
        .then(result => {
          vm.searching = false
          console.debug(result)
          if (result && result.message) {
            if (result.code == 200) {
              vm.$lConfig.User = JSON.stringify(vm.me)
              vm.$snotify.success(result.message)
            } else {
              vm.$snotify.warning(result.message)
            }
          }
        })
        .catch(error => {
          console.debug(error)
          console.debug(error.response)
          vm.searching = false
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    removeStore(store) {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `me/removeStore/${store.id}`)
        .then(result => {
          vm.searching = false
          console.debug(result)
          if (result) {
            vm.me = result
          }
          vm.$snotify.success('Loja removida')
        })
        .catch(error => {
          console.debug(error)
          console.debug(error.response)
          vm.searching = false
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    },
    openAddStore() {
      var vm = this
      vm.addStore = {}
      if (!vm.stores || vm.stores.length <= 0) {
        vm.$snotify.warning('Carregando lojas... Aguarde alguns instantes')
        vm.fetchStores().then(() => {
          vm.dialogs.addStore = true
        })
      } else {
        vm.dialogs.addStore = true
      }
    },
    fetchStores() {
      var vm = this
      vm.searching = true
      return new Promise((resolve, reject) => {
        vm.$API
          .Request('post', `stores/list`)
          .then(result => {
            if (result && result.data) {
              vm.stores = result.data
            }
            vm.searching = false
            resolve(true)
          })
          .catch(error => {
            console.debug(error)
            vm.searching = false
            vm.$snotify.error(error.message)
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              if (error.response.data.code == 200) {
                vm.$snotify.success(error.response.data.message)
              } else {
                vm.$snotify.error(error.response.data.message)
              }
            }
            reject(error)
          })
      })
    },
    addNewStore() {
      var vm = this
      vm.searching = true
      vm.$API
        .Request('post', `me/addStore/${vm.addStore}`)
        .then(result => {
          vm.searching = false
          vm.addStore = null
          vm.dialogs.addStore = false
          console.debug(result)
          if (result) {
            vm.me = result
          }
          vm.$snotify.success('Loja adicionada')
        })
        .catch(error => {
          console.debug(error)
          console.debug(error.response)
          vm.searching = false
          vm.$snotify.error(error.message)
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            if (error.response.data.code == 200) {
              vm.$snotify.success(error.response.data.message)
            } else {
              vm.$snotify.error(error.response.data.message)
            }
          }
        })
    }
  },
  created() {
    var vm = this
    vm.getMe()
  },
  mounted() {}
}
</script>
